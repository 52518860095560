import {
  Role,
  CertificatePaymentType,
  CertificateType,
  SessionStatus,
  YEARS_OLD,
} from './enum.helpers.jsx';

export const getStatusName = (statusId) => SessionStatus[statusId];
export const getCertificateType = (typeId) => CertificateType[typeId];
export const getAdvertPaymentType = (typeName) => CertificatePaymentType[typeName];
export const getRoleByName = (role, student) => {
  if (role) {
    return Role[role](student);
  } else {
    return 'Нету';
  }
};
export const getYearsByName = (years) => YEARS_OLD[years];
