import { useContext, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import { UserContext } from 'src/context.jsx';

const UpdateCrmNotif = () => {
  const { t } = useTranslation();
  const { user, api_v2 } = useContext(UserContext);

  const fetchIsUpdateCRM = async () => {
    await api_v2.get(`/core/configuration/`).then((res) => {
      if (res.data.crm_update_notification) {
        fetch('https://cr.vean-tattoo.pl/api/v2/core/crm-update-info/?last=true')
          .then((response) => response.json()) // Преобразование ответа в JSON
          .then((res) => {
            if (!!res?.results.length) {
              if (getCRMUpdateLocalStorage()?.id !== res.results[0].id) {
                setCRMUpdateLocalStorage(res.results[0]);
              }
              if (getCRMUpdateLocalStorage()?.is_active) {
                getToast(res.results[0]);
              }
            }
          });
      }
    });
  };

  const setCRMUpdateLocalStorage = (data) => {
    getToast(data);
    return localStorage.setItem('crm_update', JSON.stringify(data));
  };

  const getCRMUpdateLocalStorage = () => {
    return JSON.parse(localStorage.getItem('crm_update'));
  };

  const updateCRMUpdateLocalStorage = () => {
    const data = getCRMUpdateLocalStorage();
    return localStorage.setItem('crm_update', JSON.stringify({ ...data, is_active: false }));
  };

  useEffect(() => {
    fetchIsUpdateCRM();
  }, []);

  const interval = useRef(null);

  useEffect(() => {
    interval.current = setInterval(
      () => {
        fetchIsUpdateCRM();
      },
      7 * 60 * 1000,
    );
    return () => clearInterval(interval.current);
  }, [interval]);

  const getToast = (update) => {
    return Toast.fire({
      title: `Заливка правок: ${update.type}<br>c ${update.start_update} до ${update.end_update}<br>Версия: ${update?.version} `,
      background: '#f8f8f8',
      html: `<div class="swal-div">${update.description}</div> </br> <a href='/crm/stats/crm-updates/${update.id}' target="_blank" style={{ fontSize: '18px' }}>
      Перейти к обновлению
    </a></>`,
      confirmButtonText: 'OK',
      preConfirm: () => {
        updateCRMUpdateLocalStorage();
      },
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
  });
};

export default UpdateCrmNotif;
