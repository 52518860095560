import React, { useContext } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Field, Formik } from 'formik';
import { Button, Col, Form, Row } from 'reactstrap';
import { isRole, isRoleStore } from 'helpers/func.helpers';
import { Input } from 'components/Forms/FormikComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from 'src/context.jsx';
import i18next from 'i18next';

const MySwal = withReactContent(Swal);

const UserForm = ({
  api,
  item,
  propositionFieldName,
  fieldName,
  refetchData,
  getValueFromUnits,
  getUnitsFromValue,
}) => {
  const sendProposition = async (values) => {
    if (!item[propositionFieldName]?.id) {
      return api
        .post(`/parlor-store-propositions/`, {
          parlor_store: item.id,
          [fieldName]: getValueFromUnits(values.value, item.item),
          comment_who_proposed: values.comment,
        })
        .then(() => {
          refetchData();
          Swal.fire(i18next.t('swal.success'), '', 'success');
        })
        .catch((error) => {
          Swal.fire({
            title: JSON.stringify(error.response.data),
            icon: 'error',
          });
        });
    } else {
      return api
        .patch(`/parlor-store-propositions/${item[propositionFieldName]?.id}/`, {
          parlor_store: item.id,
          [fieldName]: getValueFromUnits(values.value, item.item),
          [`previous_${fieldName}`]: getValueFromUnits(item[fieldName], item.item),
          comment_who_proposed: values.comment,
        })
        .then(() => {
          refetchData();
          Swal.fire(i18next.t('swal.success'), '', 'success');
        })
        .catch((error) => {
          Swal.fire({
            title: JSON.stringify(error.response.data),
            icon: 'error',
          });
        });
    }
  };
  const defaultValues = {
    value: item[propositionFieldName]
      ? getUnitsFromValue(item[propositionFieldName][fieldName], item.item)
      : '',
    comment: item[propositionFieldName] ? item[propositionFieldName]?.comment_who_proposed : '',
  };
  return (
    <Row>
      <Formik enableReinitialize={true} initialValues={defaultValues} onSubmit={sendProposition}>
        {(form) => (
          <Form onSubmit={form.handleSubmit}>
            <Row className='justify-content-center'>
              <Col xs={6}>
                <Field
                  name='value'
                  type='number'
                  inline={true}
                  label={i18next.t('stat_7.offer')}
                  component={Input}
                />
              </Col>
              <Field
                name='comment'
                inline={true}
                label={i18next.t('stat_7.offer_comment')}
                type='textarea'
                component={Input}
              />
              <Button type='submit' id='confirm' className='btn swl-cstm-btn-no-jst-prceed'>
                {i18next.t('stat_7.give_offer')}
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </Row>
  );
};
const ModerForm = ({
  api,
  item,
  propositionFieldName,
  fieldName,
  refetchData,
  getValueFromUnits,
  getUnitsFromValue,
}) => {
  const confirmProposition = async (values) => {
    if (item[propositionFieldName]?.id) {
      api
        .patch(`/parlor-store-propositions/moderate/${item[propositionFieldName]?.id}/`, {
          accept: true,
          comment_who_moderated: values.comment ? values.comment : 'ok',
          parlor_store: item.id,
        })
        .then(() => {
          refetchData();
          Swal.fire(i18next.t('swal.success'), '', 'success');
        })
        .catch((error) => {
          Swal.fire({
            title: JSON.stringify(error.response.data),
            icon: 'error',
          });
        });
    } else {
      return api
        .post(`/parlor-store-propositions/`, {
          parlor_store: item.id,
          [fieldName]: getValueFromUnits(values.value, item.item),
          [`previous_${fieldName}`]: getValueFromUnits(item[fieldName], item.item),
          comment_who_proposed: values.comment,
        })
        .then(() => {
          refetchData();
          Swal.fire(i18next.t('swal.success'), '', 'success');
        })
        .catch((error) => {
          Swal.fire({
            title: JSON.stringify(error.response?.data),
            icon: 'error',
          });
        });
    }
  };

  const rejectProposition = (form) => {
    return api
      .patch(`/parlor-store-propositions/moderate/${item[propositionFieldName]?.id}/`, {
        parlor_store: item.id,
        accept: false,
        comment_who_moderated: form.values.comment ? form.values.comment : '',
      })
      .then(() => {
        refetchData();
        Swal.fire(i18next.t('swal.success'), '', 'success');
      })
      .catch((error) => {
        Swal.fire({
          title: JSON.stringify(error.response.data),
          icon: 'error',
        });
      });
  };

  const defaultValues = {
    value: item[propositionFieldName]
      ? getUnitsFromValue(item[propositionFieldName][fieldName], item.item)
      : '',
    comment: item[propositionFieldName] ? item[propositionFieldName]?.comment_who_moderated : '',
  };
  return (
    <Row className='justify-content-center'>
      <Formik enableReinitialize={true} initialValues={defaultValues} onSubmit={confirmProposition}>
        {(form) => (
          <Form onSubmit={form.handleSubmit} autoComplete='new-forma'>
            <Row>
              <Col xs={6}>
                <Field
                  name='value'
                  type='number'
                  inline={true}
                  label={i18next.t('stat_7.offer')}
                  component={Input}
                />
              </Col>
              <Col xs={4} className='d-flex align-items-center'>
                {item.item.item_units.length && (
                  <p style={{ color: 'black' }}>{item.item.item_units[0].unit.title}</p>
                )}
              </Col>
            </Row>
            <Row class='m-3 d-flex'>
              <p
                style={{
                  color: 'black',
                  fontSize: '13px',
                  textAlign: 'start',
                  display:
                    item[propositionFieldName] && item[propositionFieldName]?.comment_who_proposed
                      ? 'block'
                      : 'none',
                }}
              >
                <span style={{ color: 'grey' }}>{i18next.t('stat_7.offer_comment')}:</span>{' '}
                {item[propositionFieldName] ? item[propositionFieldName]?.comment_who_proposed : ''}
              </p>
            </Row>
            <Field
              name='comment'
              inline={true}
              label={i18next.t('stat_7.moder_comment')}
              type='textarea'
              component={Input}
            />
            <Button type='submit' id='confirm' className='btn swl-cstm-btn-no-jst-prceed'>
              {i18next.t('stat_7.accept')}
            </Button>
            <Button
              type='button'
              id='reject'
              className='btn swl-cstm-btn-no-jst-prceed'
              onClick={() => rejectProposition(form)}
            >
              {i18next.t('stat_7.reject')}
            </Button>
          </Form>
        )}
      </Formik>
    </Row>
  );
};

export const EditValues = React.memo(
  ({
    item,
    fieldName,
    currentValue,
    propositionFieldName,
    isHideFieldName,
    isHideButton,
    title,
    storeId,
    refetchData,
    units,
    getUnitsFromValue,
    getValueFromUnits,
  }) => {
    const { user, api } = useContext(UserContext);

    const userModal = React.useCallback(
      (item) => {
        MySwal.fire({
          title: `${i18next.t('stat_7.give_offer')} ${title}`,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
          showLoaderOnConfirm: true,
          html: (
            <UserForm
              user={user}
              api={api}
              storeId={storeId}
              item={item}
              propositionFieldName={propositionFieldName}
              fieldName={fieldName}
              refetchData={() => refetchData()}
              getValueFromUnits={getValueFromUnits}
              getUnitsFromValue={getUnitsFromValue}
            />
          ),
        }).then((result) => {
          if (result.value) {
            Swal.fire(i18next.t('swal.success'), '', 'success');
          }
        });
      },
      [api],
    );

    const moderModal = React.useCallback(
      (item) => {
        MySwal.fire({
          title: `${i18next.t('stat_7.accept_or_reject')} ${title}`,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
          showLoaderOnConfirm: true,
          html: (
            <ModerForm
              user={user}
              api={api}
              storeId={storeId}
              item={item}
              propositionFieldName={propositionFieldName}
              fieldName={fieldName}
              refetchData={() => refetchData()}
              getValueFromUnits={getValueFromUnits}
              getUnitsFromValue={getUnitsFromValue}
            />
          ),
        })
          .then((value) => {
            if (value.dismiss !== 'close' && value.dismiss !== 'backdrop') {
              Swal.fire(i18next.t('swal.success'), '', 'success');
            }
          })
          .catch((error) => {
            Swal.fire({
              title: JSON.stringify(error.response.data),
              icon: 'error',
            });
          });
      },
      [api],
    );

    const hideShowPrice = (hidePrice) => {
      return api
        .patch(`/items/${item.original.id}/`, {
          category: item.original.category.id,
          [isHideFieldName]: !hidePrice,
        })
        .then(() => {
          refetchData();
        });
    };
    return (
      <div
        style={{
          display: 'flex',
          direction: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!isRole(['master'], user)
          ? isRole(['administrator', 'director'], user) && (
              <p style={{ marginBottom: '0px' }}>
                {currentValue === 0 || currentValue === '0.00' ? 'Нет в наличии' : currentValue}/
                <span style={{ color: '#a10000', fontWeight: 'bold' }}>
                  {item.original[propositionFieldName] &&
                    getUnitsFromValue(
                      item.original[propositionFieldName][fieldName],
                      item.original.item,
                    )}
                </span>
              </p>
            )
          : currentValue >= 20
            ? i18next.t('stat_7.enough_count')
            : currentValue >= 10
              ? i18next.t('stat_7.normal_count')
              : currentValue >= 5 || currentValue > 1
                ? i18next.t('stat_7.min_count')
                : currentValue <= 0 && i18next.t('stat_7.not_available')}
        {isRole(['owner', 'economist'], user) && (
          <p style={{ marginBottom: '0px' }}>
            {currentValue}/
            <span style={{ color: '#a10000', fontWeight: 'bold' }}>
              {item.original[propositionFieldName] &&
                getUnitsFromValue(
                  item.original[propositionFieldName][fieldName],
                  item.original.item,
                )}
            </span>
          </p>
        )}
        {isRole(['owner', 'economist'], user) ? (
          <FontAwesomeIcon
            icon={faEdit}
            size='lg'
            className='cursor-edit'
            style={{ marginRight: '5px' }}
            onClick={() =>
              isRole(['owner', 'economist'], user)
                ? moderModal(item.original)
                : isRole(['administrator', 'director'], user) && userModal(item.original)
            }
          />
        ) : (
          <FontAwesomeIcon
            icon={faEdit}
            size='lg'
            className='cursor-edit'
            style={{ marginRight: '5px' }}
            onClick={() =>
              isRole(['owner', 'economist'], user)
                ? moderModal(item.original)
                : isRole(['administrator', 'director'], user) && userModal(item.original)
            }
          />
        )}
        {isHideButton && isRoleStore(['moderators'], user) && (
          <Button
            color={item?.original[isHideFieldName] ? 'primary' : 'danger'}
            onClick={() => hideShowPrice(item?.original[isHideFieldName])}
          >
            {item?.original[isHideFieldName] ? i18next.t('stat_7.show') : i18next.t('stat_7.hide')}
          </Button>
        )}
        {units && <p className='m-0'>({units})</p>}
      </div>
    );
  },
);
