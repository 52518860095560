import React, { useState, useContext } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { handleAxiosErrorSwal } from 'helpers';

import { useQuery, gql } from '@apollo/client';
import moment from 'moment';
import { useDepartments } from '../../Tasks/Components';
import Swal from 'sweetalert2';
import { useDateFilter } from 'hooks/gql.hooks';
import { UserContext } from 'src/context.jsx';

import Select from 'components/Select';

import ReactTable from 'react-table';

const DEPARTAMENT_POINT = gql`
  query categoryPointDepartment($from: timestamptz!, $to: timestamptz!, $depart: Int) {
    department: core_department(where: { is_estimation: { _eq: true }, id: { _eq: $depart } }) {
      name
      id
      category: core_categorypointdepartments(
        where: { created_at: { _gte: $from, _lte: $to }, is_active: { _eq: true } }
      ) {
        point
        point_from_director
        point_from_report
        accounts_user {
          id
          first_name
          last_name
          role
          profile: accounts_profile {
            id
            rank
          }
        }
      }
    }
  }
`;

const ModalCategoryPointDepartment = ({ isOpen, setIsOpenModal }) => {
  const { t } = useTranslation();
  const [from, renderFrom] = useDateFilter('from', moment().startOf('month').format('YYYY-MM-DD'));
  const [to, renderTo] = useDateFilter('to', moment().endOf('month').format('YYYY-MM-DD'));
  const [depart, setDepart] = useState(null);
  const pointsToNumber = { A: 5, B: 4, C: 3, D: 2 };
  const { api_v2 } = useContext(UserContext);

  const departament = useDepartments();

  const { data, refetch } = useQuery(DEPARTAMENT_POINT, {
    variables: {
      from: from,
      to: to,
      depart: depart,
    },
    fetchPolicy: 'cache-and-network',
  });

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
  const changePoint = (departmentId, typePoint) =>
    Swal.fire({
      title: 'Изменить оценку',
      input: 'number',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        return api_v2
          .post(`/core/department-point/`, {
            department: departmentId,
            [typePoint]: value,
          })
          .then(() => {
            Toast.fire({
              icon: 'success',
              title: t('swal.success'),
            });
            refetch();
          })
          .catch(handleAxiosErrorSwal);
      },
    });

  function convertScore(score) {
    if (score >= 124) return 5;
    else if (score >= 122 && score <= 123) return 4.9;
    else if (score >= 120 && score <= 121) return 4.8;
    else if (score >= 118 && score <= 119) return 4.7;
    else if (score >= 116 && score <= 117) return 4.6;
    else if (score >= 114 && score <= 115) return 4.5;
    else if (score >= 112 && score <= 113) return 4.4;
    else if (score >= 110 && score <= 111) return 4.3;
    else if (score >= 108 && score <= 109) return 4.2;
    else if (score >= 106 && score <= 107) return 4.1;
    else if (score >= 104 && score <= 105) return 4.0;
    else if (score >= 102 && score <= 103) return 3.9;
    else if (score >= 100 && score <= 101) return 3.8;
    else if (score >= 98 && score <= 99) return 3.7;
    else if (score >= 96 && score <= 97) return 3.6;
    else if (score >= 94 && score <= 95) return 3.5;
    else if (score >= 92 && score <= 93) return 3.4;
    else if (score >= 90 && score <= 91) return 3.3;
    else if (score >= 88 && score <= 89) return 3.2;
    else if (score >= 86 && score <= 87) return 3.1;
    else if (score >= 84 && score <= 85) return 3.0;
    else if (score <= 83 && score > 1) return 1.0;
    else return 0;
  }

  const newData = data?.department?.map((item) => {
    const headOfDepartData = item?.category.filter(
      (f) => f.accounts_user.profile.rank === 'Head of department',
    );

    const directorOwnerData = item?.category.filter(
      (f) =>
        ['director', 'owner'].includes(f.accounts_user.role) &&
        f.accounts_user.profile.rank !== 'Head of department',
    );

    const listPointHeadOfDepartData = headOfDepartData.filter((el) => el.point);
    const listPointDirectorOwnerData = directorOwnerData.filter((el) => el.point);

    const listPointDirector = item.category.filter((el) => el.point_from_director);
    const listPointReport = item.category.filter((el) => el.point_from_report);

    const totalPointsHeadOfDepartData = listPointHeadOfDepartData.reduce((sum, categoryItem) => {
      return sum + pointsToNumber[categoryItem.point];
    }, 0);
    const totalPointsDirectorOwnerData = listPointDirectorOwnerData.reduce((sum, categoryItem) => {
      return sum + pointsToNumber[categoryItem.point];
    }, 0);

    const totalPointsDirector = listPointDirector.reduce((sum, categoryItem) => {
      return sum + categoryItem.point_from_director;
    }, 0);
    const totalPointsReport = listPointReport.reduce((sum, categoryItem) => {
      return sum + categoryItem.point_from_report;
    }, 0);

    const resultPointHeadOfDepartData =
      totalPointsHeadOfDepartData / listPointHeadOfDepartData.length;

    const resultPointDirectorOwnerData =
      totalPointsDirectorOwnerData / listPointDirectorOwnerData.length;
    const resultPointDirector = totalPointsDirector / listPointDirector.length;
    const resultPointReport = convertScore(totalPointsReport / listPointReport.length);
    const pointResult =
      (resultPointHeadOfDepartData || 0) +
      (resultPointDirectorOwnerData || 0) +
      (resultPointDirector || 0) +
      (resultPointReport || 0);

    return {
      id: item.id,
      departName: item.name,
      pointHeadOfDepart: resultPointHeadOfDepartData ? resultPointHeadOfDepartData.toFixed(1) : 0,
      pointDirectorOwnerData: resultPointDirectorOwnerData
        ? resultPointDirectorOwnerData.toFixed(1)
        : 0,
      pointFromDirector: resultPointDirector ? resultPointDirector.toFixed(1) : 0,
      pointFromReport: resultPointReport ? resultPointReport.toFixed(1) : 0,
      pointResult: pointResult ? pointResult.toFixed(1) : 0,
    };
  });

  const columns = [
    {
      Header: 'id',
      accessor: 'id',
      minWidth: 100,
    },
    {
      Header: 'Департамент',
      accessor: 'departName',
      minWidth: 100,
    },
    {
      Header: 'Оценка глав департаментов',
      accessor: 'pointHeadOfDepart',
      minWidth: 100,
    },
    {
      Header: 'Оценка управляющих',
      accessor: 'pointDirectorOwnerData',
      minWidth: 100,
    },
    {
      Header: 'Оценки от директора',
      accessor: 'pointFromDirector',
      minWidth: 100,
      Cell: (row) => {
        return (
          <div>
            <FontAwesomeIcon
              size='lg'
              cursor='pointer'
              icon={faEdit}
              color='green'
              onClick={() => changePoint(row.original.id, 'point_from_director')}
            />
            <p>{row.original.pointFromDirector}</p>
          </div>
        );
      },
    },
    {
      Header: 'Оценки с отчета',
      accessor: 'pointFromReport',
      minWidth: 100,
      Cell: (row) => {
        return (
          <div>
            <FontAwesomeIcon
              size='lg'
              cursor='pointer'
              icon={faEdit}
              color='green'
              onClick={() => changePoint(row.original.id, 'point_from_report')}
            />
            <p>{row.original.pointFromReport}</p>
          </div>
        );
      },
    },
    {
      Header: 'Итоговая',
      accessor: 'pointResult',
      minWidth: 100,
    },
  ];
  return (
    <>
      <Modal size='xl' isOpen={isOpen} backdrop='static'>
        <ModalHeader
          toggle={() => {
            setIsOpenModal(false);
          }}
          tag='h4'
        >
          Оценки департаментов
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={3}>От:{renderFrom}</Col>
            <Col lg={3}>До:{renderTo}</Col>
            <Col lg={3}>
              Департамент
              <Select options={departament} onChange={(e) => setDepart(e.value)} />
            </Col>
          </Row>

          <ReactTable
            columns={columns}
            data={newData}
            getTdProps={() => ({
              style: {
                textAlign: 'center',
                textOverflow: 'inherit',
                whiteSpace: 'normal',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              },
            })}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              setIsOpenModal(false);
            }}
            type='button'
          >
            {t('buttons.close')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalCategoryPointDepartment;
