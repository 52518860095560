import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { handleAxiosErrorSwal } from 'helpers';
import Loading from 'components/Loading';

const ForgotPassword = ({ api_v2 }) => {
  const [loading, setLoading] = useState(false);
  const checkMetodForgot = (value, key, swalQueueStep) => {
    swalQueueStep.close();
    setLoading(true);
    api_v2
      .post(`/forgot-password/`, {
        [key]: value,
      })
      .then((response) => {
        setLoading(false);
        Swal.fire(response.data.success, '', 'info');
      })
      .catch((error) => {
        setLoading(false);
        handleAxiosErrorSwal(error);
      });
  };
  const forgot = async () => {
    const swalQueueStep = await Swal.mixin({
      input: 'text',
      confirmButtonText: 'Далее &rarr;',
      showCancelButton: true,
      progressSteps: ['1', '2'],
    });
    const login = await swalQueueStep.fire({
      title: `Login`,
      text: 'Если не помните, нажмите далее и впишите email',
      preConfirm: (value) => ({ user_name: value }),
      currentProgressStep: 0,
    });
    if (login.dismiss === Swal.DismissReason.cancel) {
      swalQueueStep.close();
      return;
    }
    if (login.value.user_name) {
      return checkMetodForgot(login.value.user_name, 'user_name', swalQueueStep);
    }
    await swalQueueStep
      .fire({
        title: `Email`,
        text: 'Если не помните данные аккаунта обратитесь к поддержке',
        preConfirm: (value) => ({ email: value }),
        currentProgressStep: 1,
      })
      .then((response) => {
        checkMetodForgot(response.value.email, 'email', swalQueueStep);
      });
  };
  return (
    <div className='d-flex justify-content-center flex-column'>
      {loading && <Loading />}
      <hr align='center' width='300' size='2' color='white' />
      <a
        style={{ fontSize: '16px' }}
        href='#'
        onClick={() => forgot()}
        className='text-center text-secondary'
      >
        Забыл пароль
      </a>
    </div>
  );
};
export default ForgotPassword;
