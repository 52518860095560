import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import ReactTable from 'react-table';
import { useTranslation } from 'react-i18next';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import Tooltip from 'components/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ModalActive = ({
  size = 'lg',
  style = null,
  header = '',
  children,
  footer = true,
  modalActivate,
  neddFunction,
}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { t } = useTranslation();
  useEffect(() => {
    setModal(modalActivate);
  }, [modalActivate]);
  return (
    <Modal size={size} isOpen={modal} toggle={toggle} style={style}>
      <ModalHeader toggle={toggle}>{header}</ModalHeader>
      <ModalBody style={style} className='mt-3'>
        {children}
      </ModalBody>
      {footer && (
        <ModalFooter className='d-flex justify-content-end'>
          <Button
            onClick={() => {
              neddFunction();
              toggle();
            }}
            color='primary'
          >
            {t('buttons.close')}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default React.memo(
  ({
    size = 'lg',
    style = null,
    header = '',
    children,
    footer = true,
    ToggleComponent,
    renderToId = '',
    modalEnabled = true,
    table = null,
    modalActive = false,
  }) => {
    const [modal, setModal] = useState(modalActive);
    const toggle = () => setModal(!modal);
    const { t } = useTranslation();
    const TriggerComponent = React.cloneElement(ToggleComponent, {
      onClick: modalEnabled ? toggle : null,
    });
    const renderTo = renderToId ? document.getElementById(renderToId) : null;

    return (
      <>
        {renderTo ? ReactDOM.createPortal(TriggerComponent, renderTo) : TriggerComponent}
        <Modal size={size} isOpen={modal} toggle={toggle} style={style}>
          <ModalHeader toggle={toggle}>{header}</ModalHeader>
          {modal && (
            <ModalBody className='mt-3'>
              {children}
              {table && (
                <ReactTable
                  className={'-highlight'}
                  columns={table.columns}
                  minRows={1}
                  showPagination={table?.pagination ?? true}
                  data={table.data}
                  pageSize={table.data?.length}
                  getTdProps={() => ({
                    style: {
                      textOverflow: 'inherit',
                      whiteSpace: 'normal',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  })}
                />
              )}
            </ModalBody>
          )}
          {footer && (
            <ModalFooter className='d-flex justify-content-between'>
              <div
                style={{ display: 'flex', alignItems: 'baseline', gap: '10px', margin: '0 auto' }}
              ></div>
              <ModalBody className='text-start'>
                <a
                  href='https://docs.google.com/forms/d/e/1FAIpQLSfd-0vXAU6EET6pybjHrV1SmJC7Bra8gXEvttq9AKYTCGchYw/formResponse'
                  target='_blank'
                  onClick={toggle}
                  rel='noopener noreferrer'
                  className='btn btn-primary'
                  style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}
                >
                  <Tooltip
                    id='infotimer'
                    placement='bottom'
                    tooltipContent={
                      <>
                        <p>{t('complaints.form_in')}</p>
                        <p>{t('complaints.please_report')}</p>
                      </>
                    }
                  >
                    <FontAwesomeIcon
                      style={{
                        position: 'absolute',
                        left: '10px',
                        bottom: '-1px',
                        margin: '13px',
                        color: 'red',
                      }}
                      icon={faInfo}
                      size='lg'
                      id='infotimer'
                    />
                  </Tooltip>
                  {t('complaints.comp_sug_adm')}
                </a>
              </ModalBody>
            </ModalFooter>
          )}
        </Modal>
      </>
    );
  },
);
